export default {
  numberWithCommas(value) {  // Currency
    let res = parseFloat(value).toFixed(2);
    res = Number(res).toLocaleString('en');
    return res;
  },
  redondeo(value) {  // Redondeo a favor
    return Math.floor(value * 10) / 10;
  },
  dateSmall(date) {
    return moment(date).format('L');
  },
  dateMedium(date) {
    return moment(date).format('lll');
  },
  dateLarge(date) {
    return moment(date).format('LLL');
  },
};