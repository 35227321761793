<template>
  <!-- NO DATA -->
  <div class="float centered">
    <feather-icon
      style="margin-bottom: 2em"
      :icon="icon"
      size="100"
    />
    <h2><b>{{ title }}</b></h2>
    <p style="font-size: medium">{{ content }}</p>
    <b-button
      v-if="!noButton"
      style="margin-top: 2em"
      variant="primary"
      @click="buttonFunc"
    >
      {{ buttonText }}
    </b-button>
  </div>
</template>


<style>
.centered {
  text-align: center;
  word-wrap: break-word;
  position: absolute; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>


<script>

import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: 'LoaderIcon'
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Volver al menú'
    },
    buttonFunc: {
      type: Function,
      required: false
    },
    noButton: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
